var AnnoCorrente = () => {
  var data = new Date();
  return data.getFullYear();
}
export var Environment = () => {
  var env = {
    Wp: false,
    IDTecnico: "RT",
    IDCliente: "C",
    IDAdmin: "AZ",
    IDRoot: "SA",
    ClientiType: ['CA', 'C', 'AC'],
    filtrife: [],
    legenda: [],
    production: true,
    apiUrl: 'https://customer.klimaitalia.it/api',
    bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
    langPrefix: './assets/i18n/',
    langSuffix: '.json',
    ClientebaseUrl: '/Cliente/formazienda/c/',
    baseUrl: '/progetti/progetti',
    href: '/Login',
    homepages: [
      { Ruoli: ["AC"], link: "/Cliente/profile" },
      { Ruoli: ["AG", "K"], link: "/progetti/ordini/k" },
      { RuoliAccesso: [13], link: "/progetti/progetti" },
      { RuoliAccesso: [12], link: "/progetti/prodotti/lista" }
    ],
    //#region Layout Config
    logoPath: 'assets/images/KlimaNuovo.png',
    loginMsg: '',
    loginbgPath: 'assets/images/klimasede.jpeg',
    icon: 'assets/images/KlimaIMGxicon.png',
    logoBg: 'skin1', // six possible values: skin(1/2/3/4/5/6),
    navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
    userCircleColor: "",
    theme: 'light',
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    //#endregion
    //#region Moduli
    Modules: {
      "twoFactorAuth": false,
      "Admin": true,
      "Personale": true,
      "Clienti": true,
      "Interventi": false,
      "Prima_Nota": false,
      "Listini": false,
      "Progetti": true,
      "Prodotti": true,
      "Documenti": true,
      "Scadenzario": false,
      "Spedizioni": false,
      "Stampe": true,
      "Task": true,
      "Shared": false,
      "Dashboard": true,
      "Settings": true,
      "Formazione": false,
      "VersioneCarbotti": false,
      "PartiProdotto": false,
      "Parti": false,
      "Rma": true,
      "Ecommerce": true,
      "ScadenzeFiglie": false,
      "Registrazione": false,
      "Ticket": true,
      "Acquisti": false,
      "taskboard": false,
      "Camera": false,
      "Valutazioni": false
    },
    //#endregion
    //#region Addon
    DashboardAddon: [
      {
        url: '/progetti/progetti/dashboard',
        initObj: [
          { campo: 'anno', value: AnnoCorrente() },
          { campo: 'trimestre', value: 'A' },
        ],
        filters: [
          { nome: 'Anno:', oggetti: ['anno'], modificabile: true, inputType: 'numeric', class: 'col-lg-3', OnChange: 'refresh' },
          {
            nome: "Periodo",
            source: [{ id: 'A', label: 'Anno intero' }, { id: "1", label: "Primo Trimestre" }, { id: "2", label: "Secondo Trimestre" }, { id: "3", label: "Terzo Trimestre" }, { id: "4", label: "Quarto Trimestre" }],
            oggetti: ['cSomministrazioni'],
            internalOutput: 'trimestreObject',
            OutputField: 'trimestre',
            modificabile: true,
            OnChange: 'refresh',
            inputType: 'singleDropdown',
            placeholder: "Seleziona",
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: false,
            },
            class: 'col-lg-3',
            visibleifProhibited: true
          },
        ],
        campi: [
          { nome: "Ordini", tipo: "text", oggetto: "num_offerte", larghezza: 'col-lg-3', class: 'bg-info' },
          { nome: "Ordini chiusi", tipo: "text", oggetto: "num_offerte_chiuse", larghezza: 'col-lg-3', class: 'bg-info' },
          { nome: "€ Ordini", tipo: "text", oggetto: "ordini", class: 'bg-info', larghezza: 'col-lg-3' },
          { nome: "€ Ordini chiusi", tipo: "text", oggetto: "ordini_chiusi", larghezza: 'col-lg-3', class: 'bg-info' },
        ]
      }
    ],
    Personale: {
      Corsi: true,
      regioni: true,
      campiAggiuntivi: [
        {
          modificabile: true,
          nome: 'Agente Adhoc',
          oggetti: ['agente'],
          inputType: 'singleDropdown',
          source: '/anagrafica/clienti/agenti',
          internalOutput: 'agenteObject',
          OutputField: 'agente',
          placeholder: 'Seleziona',
          OnChange: 'OnAgenteChange',
          hideif: { hasRoles: ['AG'] },
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'nome',
            allowSearchFilter: true,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        }
      ]
    },
    PartiAddOn: [false],//  categorie associate
    TaskAddon: {
      checkList: false,
      DynamicFields: [{
        type: 'N',
        addButton: true,
        hideif: { hasNotCapacity: ['gestione-news'] },
        restRoute: '/task/news',
        Label: 'News',
        Azioni: [
          { nome: 'Anteprima', close: false, class: ' btn ', labelClass: 'ml-1 text-info font-weight-bold', type: 'GOTO', restRoute: `https://www.klimaitalia.it/prodotti/area_riservata/newsdetails/:id/it/:token`, capacita: ['gestione-news'], icon: 'fas fa-eye text-info', placeholder: 'Anteprima', hideif: { fieldnotexist: 'id' } },
          { nome: 'Aggiungi Tutti i clienti', close: false, class: ' btn ', labelClass: 'ml-1 text-info font-weight-bold', type: 'AddAllCatCom', capacita: ['gestione-news'], icon: 'fas fa-plus text-info', placeholder: 'Aggiungi Titti i clienti' },
          { nome: 'Invia Notifiche', class: ' btn ', labelClass: 'ml-1 text-orange font-weight-bold', type: 'POST', restRoute: '/task/news/send', capacita: [], icon: 'fas fa-plane text-orange', placeholder: 'Invia Notifiche ai Clienti', successMex: "Notifiche inviate", hideif: { fieldnotexist: 'id' }, parameter: [{ nome: "ids", parameter: 'clienti' }, { nome: 'news', parameter: 'id' }] },
          { nome: 'Elimina', close: true, class: ' btn ', labelClass: 'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/task/news/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-news'], icon: 'fas fa-trash text-danger', hideif: { fieldnotexist: 'id' }, placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{ nome: "id", parameter: 'id' }] },
        ],
        campi: [
          { nome: 'Titolo:', oggetti: ['nome'], modificabile: true, inputType: 'text', class: 'col-lg-12' },
          { nome: 'Body:', oggetti: ['note'], inputType: 'blackhtml', modificabile: true, class: 'col-lg-12' },
          { nome: 'Data:', oggetti: ['data_inizio_prevista'], modificabile: true, inputType: 'date', class: 'col-lg-3' },
          { nome: 'Data Fine:', oggetti: ['data_fine_prevista'], modificabile: true, inputType: 'date', class: 'col-lg-3' },
          { nome: 'Publica:', oggetti: ['publicata'], modificabile: true, inputType: 'checkbox', class: 'col-lg-3' },
          {
            type: 'image',
            oggetti: ['id_immagine'],
            imageToServer: 'immagine',
            filename: 'file',
            titleClass: 'control-label col-form-label',
            class: 'col-md-12',
            visibleifProhibited: true,
            modificabile: true
          },
          {
            prevDivClass: 'col-lg-12',
            modificabile: true,
            nome: 'Aggiungi Clienti',
            oggetti: ['addclienticatcom'],
            inputType: 'singleDropdown',
            source: '/anagrafica/clienti/promozioni',
            internalOutput: 'addCatCom',
            OnChange: "AddCatCom",
            placeholder: 'Seleziona',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'nome',
              allowSearchFilter: true,
            },
            titleClass: 'control-label col-form-label',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          { nome: 'Clienti Aggiunti', collapsable: true, collapsed: true, oggetti: ['clientiObject'], type: "table", header: [{ nome: "Cliente", oggetti: ['nominativo'] }], modificabile: true, class: 'col-lg-12', azioni: [{ ExternalOnClick: 'OnDelete', icon: 'fa-trash', tooltip: 'Elimina', color: 'red' }] },

        ]
      }]
    },
    ClientiAddOn: {
      filters: [
        { nome: "Cerca", oggetti: ["nome"], modificabile: true, inputType: "text" },
        { nome: "Non in Adhoc", oggetti: ["noAdhoc"], modificabile: true, type: "boolean", inputType: "checkbox" },
        { nome: "Da Abilitare", oggetti: ["abilita"], modificabile: true, type: "boolean", inputType: "checkbox" },
        { nome: "Ordina per data", oggetti: ["orderByData"], modificabile: true, type: "boolean", inputType: "checkbox" },
      ],
      headers: [
        { nome: "Nominativo", oggetti: ["nominativo"] },
        { nome: "Adhoc", oggetti: ["codAdhoc"] },
        { nome: "Data di registrazione", oggetti: ["dataRegistrazione"], inputType: "datetime-local" },
      ],
      codAdhoc: true,
      SendToAdhoc: true,
      alertInfoMancanti: [{ nome: "Indirizzo", field: 'indirizzo', mex: "l'indirizzo non è stato inserito" }, { nome: "Telefono", field: 'telefono', mex: "il numero di Telefono non è stato inserito" }],
      enable: true,
      info: true,
      iva: true,
      commerciale: false,
      segnalatore: false,
      segnalatoreInterno: true,
      sedi: false,
      TipoSedePrimaria: false,
      emailAccount: true,
      attivita: false,
      geolocalizzazione: true,
      ruolo: true,
      Modifica: false,
      prodotti: false,
      rma: false,
      codice_univoco: true,
      accesso: true,
      referenti: false,
      interventi: false,
      note_private: false,// rende invisibile il campo note al cliente
      rating: true,
      ruoliAccesso: true,
      compilazione: false,
      luogoNascita: false,
      dataNascita: false,
      statoCivile: false,
      titoloStudio: false,
      occupazione: false,
      invalidita: false,
      legge104: false,
      diagnosi: false,
      accompagnamento: false,
      pensione: false,
      tutelare: false,
      medico: false,
      scontoAdhoc: true,
      telMedico: false,
      terapia: false,
      checkAmm: true,
      provinciaArray: true,
      CampiAggiuntivi: [
        {
          //prevDivClass: 'col-12',
          modificabile: false,
          nome: 'Fido:',
          oggetti: ['fido'],
          inputType: 'text',
          type: 'string',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Fido Assicurativo:',
          oggetti: ['fidoAmm'],
          inputType: 'text',
          type: 'string',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        { nome: 'Sconto %', oggetti: ['sconto'], modificabile: true, inputType: 'number', hideif: { hasRoles: ['AG', 'K'] } },
        { nome: 'Sconto %', oggetti: ['sconto2'], modificabile: true, inputType: 'number', hideif: { hasRoles: ['AG', 'K'] } },
        { nome: 'Sconto %', oggetti: ['sconto3'], modificabile: true, inputType: 'number', hideif: { hasRoles: ['AG', 'K'] } },
        {
          modificabile: true,
          nome: 'KeyAccount',
          oggetti: ['keyaccount'],
          inputType: 'singleDropdown',
          source: '/anagrafica/clienti/list',
          parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 2000 }, { nome: "ruolo", value: "K" }],
          responseHasData: true,
          OutputField: 'keyaccount',
          internalOutput: 'keyaccountObject',
          placeholder: 'Seleziona',
          OnChange: 'OnkeyaccountChange',
          hideif: { hasRoles: ['AG', 'K'] },
          settings: {
            singleSelection: false,
            closeDropDownOnSelection: false,
            singleOutput: false,
            idField: 'id',
            textField: 'nominativo',
            allowSearchFilter: true,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        }
      ]
    },
    ProdottiAddOn: {
      shortDescription: true,
      classeEnergetica: true,
      logo: true,
      toggleOrdinamentoProdoti: true,
      tags: true,
      updated_at: true,
      video: true,
      dettaglio: true,
      files: true,
      cart: true,
      codice: false,
      codice_produttore: false,
      tipo: true,
      standby: true,
      tipi: true, // tipi di prodotto (1 - 2ante klima)
      cat_image: true,
      lista_prezzo: true,
      TranslatedDesc: true,
      codAdhoc: true,
      DragSort: true,
      cat_Sort: true,
      sottoTitolo: true,
      rifornimento: false,
      prodottiCorrelati: true,
      CategoriaSingola: true,
      keyaccount: true,
      CampiAggiuntivi: [
        { nome: 'prodotti.pesoVolume', oggetti: ['pesoVolume'], modificabile: true, inputType: 'number', class: 'col-lg-3' },
        { nome: 'Descrizione Stampa', oggetti: ['descrizioneStampa'], modificabile: true, inputType: 'textarea', class: 'col-lg-6' },
      ],
    },
    CalendarAddon: {
      managerScadenza: false,
    },
    ProgettiAddon: {
      commerciale: false,
      segnalatore: false,
      categoriaVoceDiCosto: false,
      lavorato: false,
      OggettoNascosto: true,
      HideColore: true,
      HidePrezzo: false,
      gestore: true,
      costoSpedizione: true,
      personalizzazioni: true,
      destinazioni: true,
      inviaEmail: true,
      scontoAdhoc: true,
      HideIvato: true,
      pagato: true,
      keyaccount: true,
      CampiAggiuntivi: [ // campi aggiuntiv voci di costo
        { nome: 'Sconto %', oggetti: ['sconto'], modificabile: true, inputType: 'number', MathOperation: { MathType: '-', NumType: 'percentage' } },
        { nome: 'Sconto %', oggetti: ['sconto2'], modificabile: true, inputType: 'number', MathOperation: { MathType: '-', NumType: 'percentage' } },
        { nome: 'Sconto %', oggetti: ['sconto3'], modificabile: true, inputType: 'number', MathOperation: { MathType: '-', NumType: 'percentage' } },
        { nome: 'Trasporto €', oggetti: ['spedizione'], modificabile: true, inputType: 'number', MathOperation: { MathType: '+', NumType: 'number' } },
        { nome: 'Disponibilità', oggetti: ['disponibilitaProdotto'], modificabile: false, inputType: 'number' },
        { nome: 'Merce in Arrivo', oggetti: ['materasso'], modificabile: false, inputType: 'number' },
        // {nome:'Data Merce in Arrivo', oggetti:['dataMaterasso'], modificabile: false, inputType: 'date'},
        { nome: 'Data Evas. Prev.', oggetti: ['dataConsegna'], modificabile: true, inputType: 'date' },
      ],
      CampiAggiuntiviLista: [
        { nome: 'Offerta.Conferma', oggetti: ['data_conferma'], type: 'date' },
        { nome: 'CO Adhoc', oggetti: ['codiceOrdineAdhoc'], type: 'text' },
      ],
      filters: [
        { nome: 'Offerta.Codice', oggetti: ['codice'], modificabile: true, inputType: 'text', class: 'col-lg-9' },
      ],
      Revisioni: true,
      tipoPagamento: true,
      banche: true,
      francoDeposito: true,
      ColoriLista: [
        { colore: "#ff0000", campo: "data_creazione", label: "Ordine da Lavorare" },
        { colore: "#9d9800", campo: "data_emissione", label: "Ordine in attesa di accettazione" },
        { colore: "#0000ff", campo: "data_accettazione", label: "Ordine confermato dal cliente" },
        { colore: "#7979ff", campo: "data_conferma", label: "Ordine inviato in adhoc" }
      ],
      CampiOfferta: [
        {
          prevDivClass: 'col-12',
          modificabile: false,
          nome: 'Ragione Sociale:',
          oggetti: ['clienteObject'],
          field: ['nominativo'],
          inputType: 'text',
          type: 'object',
          hideif: { fieldnotexist: 'clienteObject' },
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Indirizzo:',
          oggetti: ['clienteObject'],
          field: ['indirizzo'],
          hideif: { fieldnotexist: 'clienteObject' },
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Cap:',
          oggetti: ['clienteObject'],
          hideif: { fieldnotexist: 'clienteObject' },
          field: ['cap'],
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Città:',
          oggetti: ['clienteObject'],
          hideif: { fieldnotexist: 'clienteObject' },
          field: ['citta'],
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Provincia:',
          oggetti: ['clienteObject'],
          hideif: { fieldnotexist: 'clienteObject' },
          field: ['provincia'],
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Partita IVA:',
          oggetti: ['clienteObject'],
          hideif: { fieldnotexist: 'clienteObject' },
          field: ['piva'],
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Codice AdHoc:',
          oggetti: ['clienteObject'],
          hideif: { fieldnotexist: 'clienteObject' },
          field: ['codAdhoc'],
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Telefono:',
          oggetti: ['clienteObject'],
          hideif: { fieldnotexist: 'clienteObject' },
          field: ['telefono'],
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'E-mail:',
          oggetti: ['clienteObject'],
          hideif: { fieldnotexist: 'clienteObject' },
          field: ['email'],
          inputType: 'text',
          type: 'object',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Fido:',
          oggetti: ['fido'],
          inputType: 'text',
          type: 'string',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          modificabile: false,
          nome: 'Fido Assicurativo:',
          oggetti: ['fidoAssicurativo'],
          OnChange: 'OnFidoAssicurativoChange',
          inputType: 'text',
          type: 'string',
          titleStyle: { 'font-size': 'medium', 'margin-right': '5px' },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          inputStyle: { font: 'message-box', 'font-size': '20px' },
          visibleifProhibited: true,
        },
        {
          prevDivClass: 'col-12',
          modificabile: true,
          nome: 'Agente',
          oggetti: ['agente'],
          inputType: 'singleDropdown',
          source: 'Agenti',
          internalOutput: 'agenteObject',
          placeholder: 'Seleziona',
          OnFilter: 'filtroAgente',
          OnChange: 'OnAgenteChange',
          disabledif: { hasRoles: ['AG'] },
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            allowSearchFilter: true,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        // {
        //   modificabile:true,
        //   nome: 'Offerta.Responsabile',
        //   oggetti: ['manager'],
        //   inputType: 'singleDropdown',
        //   source: 'managers',
        //   internalOutput: 'managerObject',
        //   OnFilter: 'filtroManager',
        //   OnChange: 'OnManagerChange',
        //   placeholder: 'Seleziona',
        //   settings: {
        //     singleSelection: true,
        //     closeDropDownOnSelection: true,
        //     singleOutput:true,
        //     idField: 'id',
        //     textField: 'fullName',
        //     searchPlaceholderText: "inserire almeno 3 caratteri",
        //     allowSearchFilter: true,
        //     allowRemoteDataSearch: true,
        //   },
        //   titleClass: 'control-label col-form-label'  ,
        //   class: 'col-lg-3',
        //   visibleifProhibited: true
        // },
        // {
        //   modificabile:true,
        //   nome: 'cliente.commerciale',
        //   oggetti: ['commerciale'],
        //   inputType: 'singleDropdown',
        //   source: 'managers',
        //   internalOutput: 'commerciale',
        //   OnFilter: 'filtroCommerciale',
        //   placeholder: 'Seleziona',
        //   settings: {
        //     singleSelection: true,
        //     closeDropDownOnSelection: true,
        //     singleOutput:true,
        //     idField: 'id',
        //     textField: 'fullName',
        //     searchPlaceholderText: "inserire almeno 3 caratteri",
        //     allowSearchFilter: true,
        //     allowRemoteDataSearch: true,
        //   },
        //   titleClass: 'control-label col-form-label'  ,
        //   class: 'col-lg-3',
        //   visibleifProhibited: true
        // },{
        //   modificabile:true,
        //   nome: 'cliente.segnalatore',
        //   oggetti: ['clienti'],
        //   inputType: 'singleDropdown',
        //   source: 'clienti',
        //   internalOutput: 'segnalatore',
        //   OnFilter: 'filtrosegnalatore',
        //   placeholder: 'Seleziona',
        //   settings: {
        //     singleSelection: true,
        //     closeDropDownOnSelection: true,
        //     singleOutput: true,
        //     idField: 'id',
        //     textField: 'nominativo',
        //     searchPlaceholderText: "inserire almeno 3 caratteri",
        //     allowSearchFilter: true,
        //     allowRemoteDataSearch: true,
        //     noDataAvailablePlaceholderText:"Nessun Cliente trovato ",
        //     moreButton: true,
        //     moreButtonText: "Cerca in AdHoc",
        //   },
        //   titleClass: 'control-label col-form-label'  ,
        //   class: 'col-lg-3',
        //   visibleifProhibited: true
        // },
        {
          modificabile: true,
          nome: 'Gestore',
          oggetti: ['manager'],
          inputType: 'singleDropdown',
          source: 'managers',
          disabledif: { hasRoles: ['AG'] },
          internalOutput: 'gestoreObject',
          OnFilter: 'filtroCommerciale',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'fullName',
            searchPlaceholderText: "inserire almeno 3 caratteri",
            allowSearchFilter: true,
            allowRemoteDataSearch: true,
            excludeIds: [1032, 983]
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Numero Ordine:',
          oggetti: ['codice'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-2',
          placeholder: 'Numero Ordine:',
          visibleifProhibited: true,
          prohibitedRoles: ['AG']
        },
        {
          prevDivClass: "col-md-12",
          modificabile: true,
          nome: 'Offerta.Creazione',
          oggetti: ['data_creazione'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Offerta',
          oggetti: ['data_emissione'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Accettazione',
          oggetti: ['data_accettazione'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Offerta.Conferma',
          oggetti: ['data_conferma'],
          inputType: 'date',
          disabledif: { hasRoles: ['AG'] },
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'CO',
          oggetti: ['codiceOrdine'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
          disabledif: { fieldexist: 'codiceOrdine' },
          hideif: { fieldnotexist: 'codiceOrdine' }
        },
        {
          modificabile: true,
          nome: 'CO AdHoc',
          oggetti: ['codiceOrdineAdhoc'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
          disabledif: { fieldexist: 'codiceOrdineAdhoc' },
          hideif: { fieldnotexist: 'codiceOrdineAdhoc' }
        },
        {
          modificabile: true,
          nome: 'Note',
          oggetti: ['note'],
          inputType: 'textarea',
          titleClass: 'control-label col-form-label',
          class: 'col-md-6',
          visibleifProhibited: true
        },
        {
          modificabile: true,
          nome: 'Note Private',
          oggetti: ['note_private'],
          inputType: 'textarea',
          titleClass: 'control-label col-form-label',
          class: 'col-md-6',
          visibleifProhibited: false
        },
        {
          modificabile: true,
          nome: 'Tracking:',
          oggetti: ['tracking'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-12',
          placeholder: 'Tracking:',
          visibleifProhibited: true,
          prohibitedRoles: ['AG']
        },
        {
          modificabile: false,
          nome: 'Preventivo Inviato:',
          oggetti: ['ordineCliente'],
          inputType: 'html',
          titleClass: 'control-label col-form-label',
          class: 'col-lg-12',
          hideif: { fieldnotexist: 'ordineCliente' },
          placeholder: 'Preventivo Inviato',
          visibleifProhibited: true
        },
      ],
      CampiSottoOfferta: [
        {
          modificabile: true,
          nome: 'Sede',
          oggetti: ['codAdhoc'],
          inputType: 'singleDropdown',
          source: 'sediAdhoc',
          internalOutput: 'sedeObjectAdhoc',
          OnFilter: 'filtroSedeAdhoc',
          OnChange: 'SedeAdhocChange',
          placeholder: 'Seleziona',
          settings: {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'codAdhoc',
            textField: 'fullAddress',
            searchPlaceholderText: "Cerca",
            allowSearchFilter: true,
            allowRemoteDataSearch: false,
          },
          titleClass: 'control-label col-form-label',
          class: 'col-lg-3',
          prevDivClass: 'col-12',
          visibleifProhibited: true
        },

        {
          prevDivClass: 'col-12',
          modificabile: true,
          nome: 'Referente di consegna',
          oggetti: ['consegna'],
          field: ['nominativo'],
          inputType: 'text',
          type: 'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
          hideif: { fieldexist: 'sedeObjectAdhoc' }
        },
        {
          modificabile: true,
          nome: 'Indirizzo',
          oggetti: ['consegna'],
          field: ['indirizzo'],
          inputType: 'text',
          type: 'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
          hideif: { fieldexist: 'sedeObjectAdhoc' }
        },
        {
          modificabile: true,
          nome: 'CAP',
          oggetti: ['consegna'],
          field: ['cap'],
          inputType: 'text',
          type: 'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
          hideif: { fieldexist: 'sedeObjectAdhoc' }
        },
        {
          modificabile: true,
          nome: 'Città',
          oggetti: ['consegna'],
          field: ['citta'],
          inputType: 'text',
          type: 'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
          hideif: { fieldexist: 'sedeObjectAdhoc' }
        },
        {
          modificabile: true,
          nome: 'Provincia',
          oggetti: ['consegna'],
          field: ['provincia'],
          inputType: 'text',
          type: 'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
          hideif: { fieldexist: 'sedeObjectAdhoc' }
        },
        {
          modificabile: true,
          nome: 'Telefono',
          oggetti: ['consegna'],
          field: ['telefono'],
          inputType: 'text',
          type: 'object',
          titleClass: 'control-label col-form-label',
          class: 'col-md-3',
          visibleifProhibited: true,
        },
        {
          prevDivClass: "col-12",
          modificabile: true,
          nome: 'Check Amministrativo',
          oggetti: ['checkAmm'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-md-1',
          visibleifProhibited: true,
          prohibitedRoles: ['AG']
        },
        {
          modificabile: true,
          nome: 'Contrassegno',
          oggetti: ['contrassegno'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-md-1',
          visibleifProhibited: true,
          prohibitedRoles: ['AG']
        },
        {
          modificabile: true,
          nome: 'Porto Franco',
          oggetti: ['portoFranco'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-md-1',
          visibleifProhibited: true,
          prohibitedRoles: ['AG']
        },
        {
          modificabile: true,
          nome: 'Porto Assegnato',
          oggetti: ['portoAssegnato'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-md-1',
          visibleifProhibited: true,
          prohibitedRoles: ['AG']
        },
        {
          modificabile: true,
          nome: 'Annullato:',
          oggetti: ['annullato'],
          inputType: 'checkbox',
          titleClass: 'control-label col-form-label',
          class: 'col-md-1',
          visibleifProhibited: true,
        },
        {
          modificabile: true,
          nome: 'Data Annullamento:',
          oggetti: ['dataAnnullato'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-2',
          visibleifProhibited: true,
        },
        {
          modificabile: true,
          nome: 'VS Riferimento ordine N.:',
          oggetti: ['rifOrdine'],
          inputType: 'text',
          titleClass: 'control-label col-form-label',
          class: 'col-md-2',
          visibleifProhibited: true,
        },
        {
          modificabile: true,
          nome: 'del:',
          oggetti: ['rifData'],
          inputType: 'date',
          titleClass: 'control-label col-form-label',
          class: 'col-md-2',
          visibleifProhibited: true,
        },
      ],
      TipiProgetto: [
        {
          nome: 'Key Account',
          id: "k",
          colore: "#1baa03",
          restRoute: "/progetti/progetti/key",
          capacita: []
        },
      ],
    },
    RmaAddOn: {
      taskGenerico: false,
      vociDiCosto: true,
      provaAcquisto: true,
      CategoriaProdotto: true,
      TipoAcquisto: true,
      TipoAcquistoSource: "ClientiType",
      Rivenditore: true,
      NumeroDocumento: false,
      DataDocumento: true,
      Ricevuta: true,
      Targhetta: true,
      CircostanzaDifetto: true,
      DescrizioneDifetto: false,
      Difetto: true,
      Telefono: false,
      Garanzia: false,
      EstensioneGaranzia: false,
      Allegati: true,
      Modello: true,
      Matricola: true,
      Brand: true,
      sottoTask: true,
      chiuso: true,
      Ritiro: {
        Enabled: true,
        Referente: true,
        Nominativo: true,
        Indirizzo: true,
        Citta: true,
        Cap: true,
        Provincia: true,
        Telefono: true,
        Email: true,
        Chiusura: true,
        Disponibilita: true
      },
      Cliente: {
        Enabled: true,
        Referente: true,
        Nominativo: true,
        Indirizzo: true,
        Citta: true,
        Cap: true,
        Provincia: true,
        Telefono: true,
        Email: true,
        Chiusura: true,
        Disponibilita: true
      },
      Assistenza: {
        Enabled: true,
        Referente: false,
        Nominativo: true,
        Indirizzo: true,
        Citta: true,
        Cap: true,
        Provincia: true,
        Telefono: true,
        Email: true,
        Chiusura: true,
        Disponibilita: true,
        Tecnico: false,
      }
    },
    //#endregion
    //#region Menu
    Menu: [
      {
        title: "Menu",
        class: "has-arrow",
        label: "Menu",
        extralink: true
      },
      {
        module: "Dashboard",
        path: "dashboard/dynamic",
        title: 'menu.dashboard',
        icon: "mdi mdi-gauge",
        capacita: ["menu-dashboard"]
      },
      {
        module: "Task",
        path: "/task/list/N/0/Vertical",
        title: 'News',
        icon: "fas fa-newspaper",
        capacita: ["menu-news"]
      },
      {
        module: "Personale",
        path: "/listautenti/personale",
        title: 'menu.PersonaleLabel',
        icon: "far fa-user",
        capacita: ["menu-persone"]
      },
      {
        module: "Clienti",
        path: "/listautenti/aziende",
        title: 'menu.clienti',
        icon: "mdi mdi-account-multiple",
        capacita: ["menu-clienti"],
        notruoliOperativi: ["K"],
      },
      {
        module: "Progetti",
        path: "progetti/progetti",
        title: 'menu.progetti',
        icon: "mdi mdi-calendar-multiple-check",
        capacita: ["menu-progetti"],
        notruoliOperativi: ["AG", "K"],
      },
      {
        module: "Progetti",
        path: "progetti/ordini/k",
        title: 'menu.progetti',
        icon: "mdi mdi-calendar-multiple-check",
        capacita: ["menu-progetti"],
        ruoliOperativi: ["AG", "K"],
      },
      {
        module: "Progetti",
        path: "progetti/destinazioni",
        title: 'Destinazioni',
        icon: "mdi mdi-airplane-takeoff",
        capacita: ["menu-destinazioni"]
      },
      // {
      //   module: "Task",
      //   path: "task/calendario",
      //   title: 'menu.calendario',
      //   icon: "mdi mdi-calendar",
      // },
      // {
      //   module: "Task",
      //   path: "task/list",
      //   title: 'menu.lista',
      //   icon: "mdi mdi-calendar"
      // },
      {
        module: "Listini",
        path: "listino/listini",
        title: 'menu.listini',
        icon: "fas fa-bars",
        capacita: ["gestione-listino"],
      },
      {
        module: "Acquisti",
        path: "acquisti/acquisti",
        title: 'menu.acquisti',
        icon: "fas fa-cart-plus"
      },
      {
        module: "Acquisti",
        path: "acquisti/lavorazioni",
        title: 'menu.lavorazioni',
        icon: "fab fa-opencart"
      },
      {
        module: "Acquisti",
        path: "acquisti/groupedlavorazioni",
        title: 'menu.groupedlavorazioni',
        icon: "fab fa-opencart",
        capacita: ["menu-acquisti"],
      },
      {
        module: "Acquisti",
        path: "acquisti/spedizioni",
        title: 'menu.spedizioni',
        icon: "far fa-paper-plane"
      },
      {
        module: "Interventi",
        title: "Attività",
        icon: "mdi mdi-wrench",
        class: "has-arrow",
        capacita: ["menu-fasi"],
        submenu: [
          {
            path: "interventi/interventi",
            title: 'menu.interventi',
            icon: "mdi mdi-wrench",
          },
          {
            path: "interventi/gantt",
            title: "Gantt",
            icon: "mdi mdi-chart-areaspline",
          }
        ]
      },
      {
        module: "Documenti",
        path: "progetti/documenti",
        title: 'menu.DocumentiLabel',
        icon: "mdi mdi-file-document",
        capacita: ["menu-documenti"]
      },
      {
        module: "Prodotti",
        path: "progetti/prodotti",
        title: 'menu.ProdottiLabel',
        icon: "mdi mdi-barcode-scan",
        capacita: ["menu-prodotti"]
      },
      {
        module: "Prodotti",
        path: "progetti/prodotti/promozioni",
        title: 'Promozioni',
        icon: "mdi mdi-barcode-scan",
        capacita: ["menu-promozioni"]
      },
      {
        module: "Formazione",
        title: 'menu.FormazioneLabel',
        icon: "mdi mdi-school",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "has-arrow",
        capacita: ["menu-servizi", "menu-formazione", "menu-calendar-servizi", "menu-utenti-servizi"],
        submenu: [
          {
            path: "formazione/media",
            title: "menu.corsiMultimediali",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-formazione"]
          },
          {
            path: "formazione/corsi",
            title: "menu.corsi",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-calendar-servizi", "menu-servizi"]
          },
          {
            path: "/listautenti/personale/mod",
            title: "menu.docenti",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-utenti-servizi"]
          },
          {
            path: "/listautenti/personale/tut",
            title: "menu.tutor",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-utenti-servizi"]
          },
          {
            path: "/listautenti/personale/stu",
            title: "menu.studenti",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu-utenti-servizi"]
          },
          {
            path: "formazione/materie",
            title: "menu.materie",
            icon: "mdi mdi-currency-eur",
            capacita: ["menu_calendar_tirocini"]
          },
          {
            path: "formazione/sedi",
            title: "menu.sedi",
            icon: "mdi mdi-currency-eur",
          }
        ]
      },
      {
        module: "Prima_Nota",
        title: "menu.Prima_NotaLabel",
        icon: "mdi mdi-cart",//mdi-calendar-multiple-check //mdi-engine-outline
        class: "has-arrow",
        capacita: ["menu-spese", "menu-fatture"],
        submenu: [
          {
            path: "primanota/fatture",
            title: "menu.fattVendita",
            capacita: ["menu-fatture"]
          },
          {
            path: "/listautenti/aziende/f",
            title: "menu.fornitori",
            capacita: ["menu-fatture"]
          },
          {
            path: "primanota/primanota",
            title: "menu.primanota",
            capacita: ["menu-spese"]
          },
          {
            path: "primanota/lista-spese",
            title: "menu.listaspese",
            capacita: ["menu-spese"]
          }
        ]
      },
      {
        module: "Parti",
        path: "progetti/parti",
        title: "menu.PartiLabel",
        icon: "mdi mdi-barcode",
        capacita: ["menu-parti"]
      },
      {
        module: "Parti",
        path: "progetti/parti/categorie-list",
        title: "Categorie Parti",
        icon: "mdi mdi-cart",
        capacita: ["menu-parti"]
      },
      {
        module: "Spedizioni",
        path: "spedizioni/lista",
        title: "menu.SpedizioniLabel",
        icon: "mdi mdi-airplane",
        capacita: ["menu-spedizioni"]
      },
      {
        module: "Stampe",
        path: "stampe/templates",
        title: "menu.StampeLabel",
        icon: "mdi mdi-printer",
        class: "has-arrow",
        capacita: ["menu-stampa"],
        submenu: [
          {
            path: "stampe/templates",
            title: "Templates",
            capacita: ["gestione-stampa"]
          },
          {
            path: "stampe/marker",
            title: "Marker",
            capacita: ["menu-stampa"]
          }
        ]
      },
      {
        module: "Settings",
        path: "settings/settings",
        title: "menu.SettingsLabel",
        icon: "mdi-settings",
        capacita: ["menu-settings"]
      },
      {
        module: "Admin",
        path: "listautenti/roles",
        title: "Ruoli",
        icon: "mdi mdi-lock-open-outline",
        capacita: ["menu-ruoli"]
      },
      {
        module: "Ticket",
        path: "ticket/list",
        title: "Tickets",
        icon: "mdi mdi-bookmark-outline",
        capacita: ["menu-ticket"]
      },
      {
        ruoliOperativi: ["AC", "C", "CA"],
        path: 'Cliente/profile',
        title: 'menu.profilo',
        icon: "far fa-user"
      }
    ],
    //#endregion
  };
  return env;
};


